import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    params: computed({
      get: () => ({
        page: state.page + 1
      })
    })
  })

  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }

  const fnListFunction = async () => {
    const res = await proxy.$CustomerSvc.postCustomerReportList(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(res.list)
    } else if (res.resultCode === '0001') {
      // 데이터 없는경우
      fnReset()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnView = item => {
    router.push({
      name: 'cs-report-view-index',
      query: { seq: item.seq }
    })
  }

  return { ...toRefs(state), fnListFunction, fnView }
}

<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="notice_list_wrap">
            <div class="list_top">
              <div class="left">
                <div class="total">총 {{ totalCnt }}건</div>
              </div>
              <div class="right"></div>
            </div>
            <div class="notice_list">
              <ul class="notice_list_ul">
                <li
                  class="notice_list_li"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="fnView(item)"
                >
                  <div class="notice_list_ttl report">
                    <div class="left">
                      <span class="cate sec_color">{{
                        $ConstCode.REPORT_TYPE[item.kind].name
                      }}</span>
                      <span>{{ item.contents }}</span>
                    </div>
                    <div class="right"></div>
                  </div>
                  <div class="notice_list_con">
                    <div class="left">
                      <div class="date">{{ item.regDt }}</div>
                    </div>
                    <div class="right">
                      <span
                        :class="[
                          { grey: item.status === '0' || item.status === '1' },
                          { color: item.status === '2' }
                        ]"
                        >{{
                          item.status === '0' || item.status === '1'
                            ? '답변대기'
                            : '답변완료'
                        }}</span
                      >
                    </div>
                  </div>
                </li>
                <observe-visible
                  :key="initCnt"
                  :page="page"
                  :limit="limit"
                  :totalCnt="totalCnt"
                  @fetch="fnListFunction"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-cs-report-index',
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
